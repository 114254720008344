<template>
  <span>
    <font-awesome-icon
      v-if="smsNotification"
      class="mr-2 gray"
      title="Only Notification via sms"
      icon="mobile"
      transform="shrink-10 down-1 right-2"
      :mask="['fas', 'bell']"
      size="2x"
    />
    <font-awesome-icon
      v-if="emailNotification"
      class="mr-2 gray"
      title="Only Notification via email"
      icon="envelope"
      transform="shrink-10 down-1 right-1"
      :mask="['fas', 'bell']"
      size="2x"
    />
    <font-awesome-icon
      v-if="anyNotification"
      title="All Notification channels active"
      class="mr-2 gray"
      icon="bell"
      size="2x"
    />
    <font-awesome-icon
      v-if="voiceNotification"
      title="Only Notification vie voice"
      class="mr-2 gray"
      icon="headset"
      size="2x"
    />
    <font-awesome-icon
      v-if="noNotification"
      title="Notification inactive"
      class="gray"
      :icon="['far', 'bell-slash']"
      size="2x"
    />
  </span>
</template>

<script>
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
  name: "JiraNotificationStatus",
  components: {
    FontAwesomeIcon
  },
  props: {
    jiraNotificationMedia: {
      type: Array,
      required: false,
      default: null
    }
  },
  computed: {
     jiraNotificationMediaLowercase () {
       if(this.jiraNotificationMedia)
       {
          return this.jiraNotificationMedia.map(v => v.toLowerCase());
       }
      else{
        return new Array();
      }
    },
    emailNotification () {
      if (this.jiraNotificationMediaLowercase.includes('email') && !this.jiraNotificationMediaLowercase.includes('sms')) {
        return true;
      }
      return false;
    },
    smsNotification () {
      if (this.jiraNotificationMediaLowercase.includes('sms') && !this.jiraNotificationMediaLowercase.includes('email')) {
        return true;
      }
      return false;
    },
    anyNotification () {
      
      if (this.jiraNotificationMediaLowercase.includes('sms') && this.jiraNotificationMediaLowercase.includes('email')) {
        return true;
      }
      return false;
    },
    voiceNotification () {
      if (this.jiraNotificationMediaLowercase.includes('voice')) {
        return true;
      }
      return false;
    },
    noNotification () {
      if (!this.jiraNotificationMediaLowercase.includes('sms') && !this.jiraNotificationMediaLowercase.includes('email') && !this.jiraNotificationMediaLowercase.includes('voice')) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>
.gray {
  color: #b2b1c5;
}
</style>
